import axios from 'axios';
import { store } from '~/store';

const api = axios.create({
  baseURL: 'https://api-dasfn-guitta.origam.com.br'
});

api.interceptors.request.use(function (config) {
  if (!config.withCredentials) {
    return config;
  }

  const { withCredentials, ...interceptedConfig } = config;

  const { token } = store.getState().auth;

  interceptedConfig.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`
  };

  return interceptedConfig;
});

export default api;